/*eslint-disable */
// import eventModel from '../../Model/event'
import request from '../../Utils/curl'
import moment from 'moment'
import Utility from "../../Utils/utility";


let events = {
  /**
   * eventList
   */
  async eventList (context, filter  = {}) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";
    try{
      let post_data = new FormData();
      post_data.append('filter', JSON.stringify(filter));

      if (context.userData && context.userData.user_role === "USERROLE11115" && context.userData.modules.organisations && Object.keys(context.userData.modules.organisations).length > 0) {
        const module_obj_id = Object.keys(context.userData.modules.organisations)[0]
        post_data.append("module_obj_id", module_obj_id)
      }
      else if (context.userData && context.userData.user_role === "USERROLE11116" && context.userData.modules.organizations && Object.keys(context.userData.modules.organizations).length > 0) {
        const module_obj_id = Object.keys(context.userData.modules.organizations)[0]
        post_data.append("module_obj_id", module_obj_id)
      }

      return await request.curl(context, "event_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at eventList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },
  /**
   * eventListPublic
   */
  async eventListPublic (context, filter  = {}) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
      post_data.append('filter', JSON.stringify(filter));
      return await request.curl(context, "event_list_public", post_data)
        .then(async response => {
          return response
        });
      }
      catch(err){
        console.error('Exception occurred at eventList() and Exception:',err.message)
      }
      finally{
        Utility.showLoader = false;
      }
    },
  /**
   * event List Dashboard
   */
   async eventListDashboard (context, filter  = {}) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";
    try{
      let post_data = new FormData();
      post_data.append('filter', JSON.stringify(filter));
      return await request.curl(context, "event_list_dashboard", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at eventListDashboard() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * eventView
   */
  async eventView (context, eventId) {
    try {
      let post_data = new FormData();
      post_data.append('event_id', eventId);
      return await request.curl(context, "event_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at eventList() and Exception:",err.message)
    }
  },

  /**
   * eventViewPublic
  */
  async eventViewPublic (context, eventId) {
    try {
      let post_data = new FormData();
      post_data.append('event_id', eventId);
      return await request.curl(context, "event_view_public", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at eventList() and Exception:",err.message)
    }
  },

  /**
   * eventAdd
   */
   async eventAdd (context, eventObj) {
    try{
      let post_data = new FormData();

      for (let key in eventObj) {
        if(key === 'event_type'){
          post_data.append(key, eventObj[key]['event_type_id']);
        }
        else if(key === 'event_level'){
          post_data.append(key, eventObj[key]['event_lev_id']);
        }
        else{
          post_data.append(key, eventObj[key]);
        }
      }

      return await request.curl(context, "event_add", post_data)
        .then(async response => {
          return response;
      });
    }
    catch(err) {
      console.error('Exception occurred at eventAdd() and Exception:',err.message)
    }
  },
  /**
   * eventEdit
   */
  async eventEdit (context, eventObj) {
    try{
      let post_data = new FormData();    
      for (let key in eventObj) {
        if(key === 'event_type' && eventObj[key] && eventObj[key]['event_type_id']){
          post_data.append(key, eventObj[key]['event_type_id']);
        }
        else if(key === 'event_level' &&  eventObj[key] && eventObj[key]['event_lev_id']){
          post_data.append(key, eventObj[key]['event_lev_id']);
        }
        else if(key === 'event_start_date' && eventObj[key] && eventObj[key]['event_start_date']){
          post_data.append(key, moment(eventObj[key]['event_start_date']).format('yyyy-MM-dd HH:mm:ss'))
        }
        else if(key === 'event_end_date' && eventObj[key] && eventObj[key]['event_end_date']){
          post_data.append(key, moment(eventObj[key]['event_end_date']).format('yyyy-MM-dd HH:mm:ss'))
        }
        else if(key === 'aff_entity_id' && eventObj[key] && eventObj[key]['aff_entity_id']) {
          post_data.append(key, eventObj[key]['aff_entity_id']   )
        }
        else if(key === 'event_is_paid' && eventObj[key] && eventObj[key]['event_is_paid']) {
          post_data.append(key, eventObj[key]['event_is_paid']   )
        }
        else{
          if (eventObj[key] || typeof eventObj[key] == 'number') {
            post_data.append(key, eventObj[key]);
          }
        }
      }

      return await request.curl(context, "event_edit", post_data)
        .then(async response => {
          Utility.showLoader = false;
          return response;
        });
    }
    catch(err){
      Utility.showLoader = false;
      console.error('Exception occurred at eventEdit() and Exception:',err.message)
    }
  },
  /**
   * eventPublishUnpublish
   */
  async eventPublishUnpublish (context, eventObj) {
    try{
      let post_data = new FormData();    
      for (let key in eventObj) {
        post_data.append(key, eventObj[key]);
      }
  
      return await request.curl(context, "event_publish_unpublish", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at eventPublishUnpublish() and Exception:',err.message)
    }
  },

  /**
   * eventDelete
   */
  async eventDelete (context, eventId) {
    try{
    let post_data = new FormData();
    
    post_data.append('event_id', eventId);

    return await request.curl(context, "event_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at eventDelete() and Exception:',err.message)
    }
  },

  /**
   * eventRsvpList
   */
  async eventRsvpList (context, eventId, eventObj = null) {
    try{
      let post_data = new FormData();
      post_data.append('event_id', eventId);
      
      if (eventObj) {
        for(let key of Object.keys(eventObj)) {
          if (eventObj[key]) {
            post_data.append(key, eventObj[key])
          }
        }
      }

      return await request.curl(context, "eventRsvp_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at eventRsvpList() and Exception:',err.message)
    }
  },  
 /**
   * eventRsvpSync
   */
   async eventRsvpSync (context, eventId, eventObj = null) {
    try{
      let post_data = new FormData();
      post_data.append('event_id', eventId);

      if (eventObj) {
        for(let key of Object.keys(eventObj)) {
          if (eventObj[key]) {
            post_data.append(key, eventObj[key])
          }
        }
      }

      return await request.curl(context, "eventRsvp_sync", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at eventRsvpSync() and Exception:',err.message)
    }
  },  
 /**
 * eventRsvpMyEventList
 */
  async eventRsvpMyEventList (context, postObj) {
    try{
      let post_data = new FormData();
      for (const key in postObj) {
        if(key === 'where') {
          post_data.append('where', JSON.stringify(postObj.where))
        }
        else {
          post_data.append(key, postObj[key])
        }
      }
      return await request.curl(context, "eventRsvp_my_event_list", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at eventRsvpList() and Exception:',err.message)
    }
  },

  /**
   * eventRsvpAdd
   */
  async eventRsvpAdd (context, eventObj) {
    try{
      let post_data = new FormData();
      for (const key in eventObj) {
        if (eventObj[key]) {
          post_data.append(key, eventObj[key])
        }
      }

      return await request.curl(context, "eventRsvp_add", post_data)
        .then(async response => {
          Utility.showLoader = false;
          return response;
        });
    }
    catch(err) {
      Utility.showLoader = false
      console.error('Exception occurred at eventRsvpAdd() and Exception:',err.message)
    }
  },

 /**
   * eventRsvpUnsubscribe
   */
  async eventRsvpUnsubscribe (context, eventObj) {
    try{
      let post_data = new FormData();
      for (const key in eventObj) {
        if (eventObj[key]) {
          post_data.append(key, eventObj[key])
        }
      }

      return await request.curl(context, "eventRsvp_unsubscribe", post_data)
        .then(async response => {
          Utility.showLoader = false;
          return response;
        });
    }
    catch(err) {
      Utility.showLoader = false
      console.error('Exception occurred at eventRsvpUnsubscribe() and Exception:',err.message)
    }
  },

 /**
  * Get Upcoming Registered Events for the Organisation
  */
  async organisationViewUpcomingEventList(context, filter) {
    try {
      let post_data = new FormData();
      post_data.append('org_id', filter.org_id);
   
      return await request.curl(context, "organisation_view_public_upcoming_event_list", post_data)
        .then(async response => {
          Utility.showLoader = false;
          return response;
        });
    } 
    catch (err) {
      Utility.showLoader = false
      console.error('Exception occurred at organisationViewUpcomingEventList() and Exception:',err.message)
    }
  },

  /**
   * event_search_user
   */
  async eventSearchUser (context, filter) {
    try {
      let post_data = new FormData();
      post_data.append('search', filter.search);
   
      return await request.curl(context, "event_user_search", post_data)
        .then(async response => {
          Utility.showLoader = false;
          return response;
        });
    } 
    catch (err) {
      Utility.showLoader = false
      console.error('Exception occurred at event_search_user() and Exception:',err.message)
    }
  },

  /**
   *  Add the user into the RSVP and Meeting report table
   */
  async eventUserBoothCheckin(context, userObj) {
    try {
      let post_data = new FormData();
      for(let key of Object.keys(userObj)) {
        if (userObj[key] || typeof userObj[key] == 'number' ) {
          post_data.append(key, userObj[key])
        }
      }
      
      return await request.curl(context, "event_user_booth_checkin", post_data)
      .then(async response => {
        Utility.showLoader = false;
        return response;
      });
    } 
    catch (error) {
      Utility.showLoader = false
      console.error('Exception occurred at eventUserBoothCheckin() and Exception:',err.message)
    }
  },

  /**
   * eventAnalyticsBoothCheckinReport
   */
   async eventAnalyticsBoothCheckinReport (context, eventId, eventObj = null) {
    try{
      let post_data = new FormData();
      post_data.append('event_id', eventId);
      
      if (eventObj) {
        for(let key of Object.keys(eventObj)) {
          if (eventObj[key]) {
            post_data.append(key, eventObj[key])
          }
        }
      }

      return await request.curl(context, "event_analytics_booth_checkin_report", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at event_analytics_booth_checkin_report() and Exception:',err.message)
    }
  },

  /**
   *  Mark attendance is RSVP table 
   */
  async eventUserCheckin(context, eventObj) {
    try {
      let post_data = new FormData();
      post_data.append("user_email", eventObj.user_email);
      post_data.append("event_id", eventObj.event_id);
      post_data.append("duration", eventObj.duration)      
      return await request.curl(context, "event_user_checkin", post_data)
      .then(async response => {
        Utility.showLoader = false;
        return response;
      });
    } 
    catch (error) {
      Utility.showLoader = false
      console.error('Exception occurred at event_user_checkin() and Exception:',err.message)
    }
  },

  /**
   * eventUserRegistrationViaContactUsForm
   */
  async eventUserRegistrationViaContactUsForm (context, eventObj) {
    try{
      let post_data = new FormData();

      if (eventObj) {
        for(let key of Object.keys(eventObj)) {
          if (eventObj[key]) {
            post_data.append(key, eventObj[key])
          }
        }
      }
      return await request.curl(context, "event_user_registration_via_contactusform", post_data)
        .then(async response => {
          Utility.showLoader = false;
          return response;
        });
    }
    catch(err) {
      Utility.showLoader = false
      console.error('Exception occurred at eventUserRegistrationViaContactUsForm() and Exception:',err.message)
    }
  },
  /**
   * eventParticipateUniversity
   */
  async eventParticipateUniversity (context, filter  = {}) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
      post_data.append('filter', JSON.stringify(filter));
      return await request.curl(context, "event_participate_organisation_list", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at eventParticipateUniversity() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * eventDuplicate
   */
   async eventDuplicate (context, parentEvent) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();

      for (let key in parentEvent) {
        if (parentEvent[key] || typeof parentEvent[key] == 'number') {
          if (typeof parentEvent[key] == "object") {
            post_data.append(key, JSON.stringify(parentEvent[key]));
          }
          else {
            post_data.append(key, parentEvent[key]);
          }

          return await request.curl(context, "event_duplicate", post_data)
          .then(async response => {
            return response
          });
        }
      }
    }
    catch(err){
      console.error('Exception occurred at eventDuplicate() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  }
}

export {
  events
}
